import React from "react";
import { usePracticeCategories } from "../hooks/use-practice-categories";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "gatsby";

const PracticeCategorySection = ({ children }) => {
  const { practiceCategories } = usePracticeCategories();

  return (
    <section className="section">
      <div className="container has-text-centered py-4">
        {children}
        <div class="columns py-5 is-multiline">
          {practiceCategories.map(({ node }) => (
            <div className="column is-half">
              <div className="p-3 box has-background-white-bis hero-border-top">
                <div className="is-size-7 has-text-weight-semibold">
                  Practice Areas
                </div>
                <h2 className="title is-size-4">
                  <Link to={`/practice-areas/${node.slug}/`}>{node.title}</Link>
                </h2>
                <div className="content">{renderRichText(node.shortBody)}</div>
                <Link to={`/practice-areas/${node.slug}/`}>
                  <button class="button">Read more</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PracticeCategorySection;
