import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import PracticeCategorySection from "../components/practice-category-section";

export default function PracticeAreasPage() {
  return (
    <Layout>
      <Seo
        title={`Practice Areas`}
        keywords={`business lawyers,corporate lawyers,peronsal injury,small business`}
        description={
          "Our lawyers are skilled and experienced in business law and personal injury law."
        }
      />
      <div className="has-background-white-bis">
        <PracticeCategorySection large>
          <h1 className="title">Areas of Practice</h1>
          <p className="subtitle mb-6">
            When you need results, our experienced attorneys can help your
            navigate the courthouse.
          </p>
        </PracticeCategorySection>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PracticeAreaCategoriesQuery {
    allContentfulPracticeArea(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          slug
          title
          body {
            raw
          }
          shortBody {
            raw
          }
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 128
              resizingBehavior: THUMB
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }

    allContentfulPracticeAreaCategories(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          slug
          title
          body {
            raw
          }
          shortBody {
            raw
          }
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 350
              resizingBehavior: THUMB
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`;
